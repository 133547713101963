import { css } from '@emotion/css';
// import { cssObj } from '@superhuit/starterpack-blocks/css/resources/functions.css';
// import { fontWeights } from '@superhuit/starterpack-blocks/css/resources/variables.css';
// import {
// 	grid,
// 	resetFirstLastMargins,
// 	linkUnderline,
// 	gridContentDefault,
// 	gridFull,
// } from '@superhuit/starterpack-blocks/css/resources/placeholders.css';
// import {
// 	fontStyle,
// 	fluidPadding,
// 	fluidValues,
// 	fluidMargin,
// } from '@superhuit/starterpack-blocks/css/resources/mixins.css';

const getStyles = () => css({});

const getHeaderStyles = () =>
	css({
		'.supt-figure': {
			flexDirection: 'column',
			alignItems: 'flex-start',

			'.supt-figure__figcaption': {
				display: 'block !important',
			},
		},
	});

export { getStyles, getHeaderStyles };
