import cx from 'classnames';
import { useContext } from 'react';
import { useHandleInternalLinks } from '@superhuit/starterpack-hooks';
import Container from '@/next-components/container';
import Blocks from '@/next-components/blocks';
import { ThemeContext } from '@superhuit/starterpack-context';
import { omit } from '@superhuit/starterpack-utils';
import { getStyles, getHeaderStyles } from './styles.css';
import { getStyles as getPageStyles } from '../SinglePage/styles.css'
import { SinglePageThemeControls } from '../SinglePage/theming';
import { Image, SectionTwoColumnsCol } from '@superhuit/starterpack-blocks/renders';
import { HeaderA } from '../../components/organisms/HeaderA/render';
import blockHeaderA from '../../components/organisms/HeaderA/block.json';

export default function Artist({ node, preview }) {
	const blocks = node?.blocksJSON ? JSON.parse(node.blocksJSON) : [];

	const handleInternalLinks = useHandleInternalLinks();

	// Merge: defaults + theme (variant base + variant active)
	const { rawTheme, theme } = useContext(ThemeContext);
	const blockTheme = theme?.['templates']?.[SinglePageThemeControls.slug]; // theme
	const tokensTheme = rawTheme?.tokens; // tokens
	const finalTheme = omit(blockTheme, ['variants']);
	// console.log('::: Final Theme ::: ', finalTheme);

	const rootClass = cx(
		'supt-singleArtist',
		getPageStyles(tokensTheme, finalTheme),
		getStyles()
	);

	const headerClass = cx(
		getHeaderStyles()
	);

	return (
		<>
			<HeaderA title={node.title} introduction={node.artistCategories?.nodes.map((artist) => artist.name).join(", ")} slug={blockHeaderA.slug} variant='default' className={headerClass}>
				<SectionTwoColumnsCol slug={`${blockHeaderA.slug}-left`} />
				<SectionTwoColumnsCol slug={`${blockHeaderA.slug}-right`}>
					{node.featuredImage ?
					<Image
						src={node.featuredImage?.node?.relativeUrl}
						alt={node.featuredImage?.node?.altText}
						caption={node.featuredImage?.node?.caption?.replace( /(<([^>]+)>)/ig, '')}
						width={node.featuredImage?.node?.mediaDetails?.width}
						height={node.featuredImage?.node?.mediaDetails?.height}
					/>
					: null}
				</SectionTwoColumnsCol>
			</HeaderA>
			<Container className={rootClass} onClick={handleInternalLinks}>
				<Blocks blocks={blocks} excludes={/^supt\/header-a/g} />
			</Container>
		</>
	);
}
